import React, { lazy, Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Loader from './components/Helper/Loader';
import ScrollToTop from './components/Helper/ScrollToTop';

const Error = lazy(() => import('./components/Error'));
const Home = lazy(() => import('./components/Myhome'));
const ProductDetails = lazy(() => import('./components/Myhome/ProductDetails'));
const ContactPage = lazy(() => import('./components/Myhome/ContactPage'));
const Categories = lazy(() => import('./components/Myhome/Categories'));
const Landing = lazy(() => import('./components/Myhome/Landing'));
const ServicesAr = lazy(() => import('./components/Myhome/ServicesPage/ServicesPageAr'));
const ServicesEn = lazy(() => import('./components/Myhome/ServicesPage/ServicesPageEn'));
const Productspage = lazy(() => import('./components/Myhome/Productspage'));

function Routes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <div className="appie-visible active">
                <Router>
                    <Suspense
                        fallback={
                            <div className="appie-loader active">
                                <Loader />
                            </div>
                        }
                    >
                        <ScrollToTop>
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route exact path="/categories" component={Categories} />
                                <Route exact path="/services/ar" component={ServicesAr} />
                                <Route exact path="/landing" component={Landing} />
                                <Route exact path="/services/" component={ServicesEn} />
                                <Route exact path="/products" component={Productspage} />
                                <Route exact path="/contact/" component={ContactPage} />
                                <Route exact path="/products/:test" component={Productspage} />
                                <Route
                                    exact
                                    path="/product-details/:productname"
                                    component={ProductDetails}
                                />
                                <Route component={Error} />
                            </Switch>
                        </ScrollToTop>
                    </Suspense>
                </Router>
            </div>
        </>
    );
}

export default Routes;
